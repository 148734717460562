import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import instagram from '../assets/images/instagram.png';
import youtube from '../assets/images/youtube.png';
import facebook from '../assets/images/facebook.png';
import axios from "axios";
import {ADDRESS} from "../constants/routes";

const Footer = () => {
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm();
	const [succMsg, setSuccMsg] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [disabled, setDisabled] = useState(false);

	window.addEventListener('resize', () => {
		setWindowWidth(window.innerWidth);
	});

	const onSubmit = data => {
		setDisabled(true);
		axios.post(ADDRESS+"/save/messages", {data}
		).then((response) => {
			if(typeof response.data.error !== "undefined") {
				setSuccMsg('');
				return setErrorMsg(response.data.error);
			}
			setErrorMsg('');
			return setSuccMsg(response.data.success);
		})
		reset({
			name: '',
			email: '',
			message2: '',
		});
		setTimeout(() => {
			setSuccMsg('');
			setErrorMsg('');
		}, 5000);
	};

	return (
		<div className="footer section">
			<div
				className="footer-container 2xl:px-9 px-6 grid 2xl:grid-cols-7 md:grid-cols-2 grid-cols-1 py-10 2xl:place-items-start lg:place-items-center"
				id="kontakt"
			>
				<div className="dokumenty-wrapper 2xl:col-span-2 col-span-1 tracking-wider 2xl:text-left text-center md:py-0 py-7">
					<h1 className="lg:tracking-wider tracking-wide">Dokumenty</h1>
					<ul className="odkazy-footer">
						<li>
							<Link to={ROUTES.GDPR}>Zásady ochrany osobních údajů</Link>
						</li>
						<li>
							<Link to={ROUTES.VOP}>Všeobecné obchodní podmínky</Link>
						</li>
					</ul>
				</div>
				<div className="kontakt-wrapper 2xl:col-span-2 col-span-1 tracking-wide 2xl:text-left text-center pt-0">
					<h1 className="lg:tracking-wider tracking-wide">Kontakt</h1>
					<div className="flex gap-x-5 borderFlex pb-2 justify-center">
						<ul>
							<li>Jan Krasinský</li>
							<li>Žďárská 145, Halže</li>
						</ul>
						<ul>
							<li>IČ: 06346804</li>
							<li>info<span style={{color: "#D71A20"}}>@</span>jankrasinsky.cz</li>
						</ul>
					</div>
					<ul className="socials flex gap-x-3 mx-auto justify-center mt-4">
						<li>
							<a
								href="https://www.instagram.com/krasinsky/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={instagram}
									alt="instagram"
									className="md:w-9 w-8 cursor-pointer"
								/>
							</a>
						</li>
						<li>
							<a
								href="https://www.youtube.com/c/AnabolicHorse"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={youtube}
									alt="youtube"
									className="md:w-9 w-8 cursor-pointer"
								/>
							</a>
						</li>
						<li>
							<a
								href="https://www.facebook.com/JanKrasinsky"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={facebook}
									alt="facebook"
									className="md:w-9 w-8 cursor-pointer"
								/>
							</a>
						</li>
					</ul>
				</div>
				<div className="2xl:order-last order-first formular-wrapper 2xl:col-span-3 md:col-span-2 col-span-1 2xl:pt-0 2xl:pb-0 2xl:mb-0 mb-3 md:pb-4 pb-2 2xl:w-full xl:w-2/3 lg:w-4/5 w-full ">
					<h1 className="tracking-wider 2xl:pb-1 pb-4 2xl:text-left text-center">
						Kontaktní formulář
					</h1>
					<form onSubmit={handleSubmit(onSubmit)} className="footer-form">
						<div className="grid sm:grid-cols-2 grid-cols-1 form-wrapper gap-x-2">
							<div className="flex justify-center items-center flex-col ">
								<input
									type="text"
									placeholder="Jméno"
									name="name"
									className="sm:w-full xs:w-2/3 w-full mb-2"
									{...register('name', { required: true })}
								/>
								{errors.name && errors.name.type === 'required' && (
									<p className="h-6 text-left text-red-500 mb-1 err">
										Toto pole je povinné!
									</p>
								)}
								<input
									type="text"
									placeholder="Email"
									name="email"
									className="sm:w-full xs:w-2/3 w-full mb-2"
									{...register('email', {
										required: 'Required',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Špatně zadaný email!',
										},
									})}
								/>
								{errors.email && errors.email.type === 'pattern' && (
									<p className="h-6 text-left text-red-500 mb-1 err">
										{errors.email.message}
									</p>
								)}

								{errors.email && errors.email.type === 'required' && (
									<p className="h-6 text-left text-red-500 mb-1 err">
										Toto pole je povinné!
									</p>
								)}
								{windowWidth < 640 ? (
									<div className="w-full">
										<textarea
											placeholder="Zpráva"
											className="sm:w-full xs:w-2/3 w-full h-auto sm:hidden flex mb-2 secondTextArea"
											style={{ resize: 'none' }}
											name="message"
											{...register('message', { required: true })}
										/>
										{errors.message && errors.message.type === 'required' && (
											<p className="h-6 text-left text-red-500 mb-1 err">
												Toto pole je povinné!
											</p>
										)}
									</div>
								) : null}
								<button className="py-1 bg-d-red text-white font-medium sm:w-full xs:w-2/3 w-full" disabled={disabled}>
									Odeslat
								</button>
							</div>
							{windowWidth > 640 ? (
								<div className="w-full">
									<textarea
										placeholder="Zpráva"
										className="w-full h-full sm:flex hidden"
										type="text"
										style={{ resize: 'none' }}
										name="message2"
										{...register('message2', { required: true })}
									/>
									{errors.message2 && errors.message2.type === 'required' && (
										<p className="h-6 text-left text-red-500 mb-1 err">
											Toto pole je povinné!
										</p>
									)}
								</div>
							) : null}
						</div>
					</form>
					{succMsg !== '' ? (
						<div className="w-full">
							<p className="h-6 text-green-500 my-3 text-center font-normal">
								{succMsg}
							</p>
						</div>
					) : (
						''
					)}
					{errorMsg !== '' ? (
						<div className="w-full">
							<p className="h-6 text-red-500 my-3 text-center font-normal">
								{errorMsg}
							</p>
						</div>
					) : (
						''
					)}
				</div>
			</div>
			<div className="py-4 text-white text-center md:text-lg sm:text-base text-sm font-medium second">
				<p>
					Copyright 2021 © Jan Krasinský Vytvořil{' '}
					<a
						href="https://yeetzone.com/"
						target="_blank"
						rel="noreferrer"
						className="transitions hover:text-d-red"
					>
						Yeetzone
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export default Footer;
