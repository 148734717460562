import {motion} from 'framer-motion';
import {pageAnimation} from '../components/animations';
import React, {useState, useEffect} from 'react';
import img from '../assets/images/cart2.svg';
import doneImg from '../assets/images/done.png';
import {HashLink as Link} from 'react-router-hash-link';
import CartForm from '../components/CartForm';
import ScrollToTop from '../components/ScrollToTop';
import * as ROUTES from '../constants/routes';
import AOS from "aos";
import {useLocation} from "react-router";

const Kosik = props => {
    const [done, setDone] = useState(false);
    const [products, setProducts] = useState([]);
    const [removed, setRemoved] = useState([]);
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(false);


    const deleteFromCart = item => {
        for (let i = 0; i < products.length; i++) {
            if (products[i].title === item.title) {
                var remove = products.splice(i, 1);
                sessionStorage.setItem('products', JSON.stringify(products));
                setRemoved(remove);
                props.setItems(products.length);
            }
        }

        props.setCartErr('');
    };

    useEffect(() => {
        setProducts(JSON.parse(sessionStorage.getItem("products")));
        let x = 0;

        if (sessionStorage.getItem('products') !== null) {
            for (let i = 0; i < JSON.parse(sessionStorage.getItem('products')).length; i++) {
                x = x + (parseInt(JSON.parse(sessionStorage.getItem('products'))[i].price))*(JSON.parse(sessionStorage.getItem('products'))[i].quantity);
            }
        }

        console.log(x);
        setTotal(x);
    }, [removed, quantity]);

    const handleQuantity = (type, value, itemID, itemTitle) => {
        setQuantity(!quantity);
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === itemID) {
                if (type === "+") {
                    products[i].quantity = products[i].quantity + 1;
                }else {
                    products[i].quantity = products[i].quantity - 1;

                    if(products[i].quantity <= 0) {
                        deleteFromCart(itemTitle);
                    }
                }
            }
        }
        sessionStorage.setItem('products', JSON.stringify(products));
        setProducts(JSON.parse(sessionStorage.getItem("products")));

    }


    return (
         <motion.div
                variants={pageAnimation}
                initial="hidden"
                animate="enter"
                exit="exit"
                transition={{type: 'linear'}}
                className="text-white z-1 kosik md:pt-48 pt-36 md:px-12 px-4"
            >
                <ScrollToTop/>
                <h1 className="font-semibold">KOŠÍK</h1>
                <h4 className="mt-4">{props.cartErr}</h4>
                {products && products.length > 0  ? (
                    <div
                        className="rounded-xl kosik__mainDiv lg:flex mx-auto xl:w-2/3 lg:pr-10 lg:pl-0 md:px-8 px-4 md:py-8 py-4 mt-12 mb-20">
                        <div className="w-full flex flex-col justify-between">
                            <div>
                                {products.map(item => {
                                    return (
                                        <div
                                            className="item flex lg:mx-10 mx-0 py-6 justify-between items-stretch relative"
                                            key={Object.keys(sessionStorage).indexOf(item)}
                                        >
                                            <div>
                                                <h1 className="mb-3 font-semibold uppercase text-left">
                                                    {item.title}
                                                </h1>
                                                <h2>
                                                    {item.price} Kč <b className={"quantitySpace"}>|</b> <label
                                                    className={"quantity"}>Množství {item.quantity} <b
                                                    className={"quantityAction"}
                                                    onClick={() => handleQuantity("+", 1, item.id, {title: item.title})}>+</b><b
                                                    className={"quantityAction"}
                                                    onClick={() => handleQuantity("-", 1, item.id, {title: item.title})}>-</b></label>
                                                </h2>
                                            </div>
                                            <div
                                                className="flex self-center cursor-pointer py-4"
                                                onClick={() => {
                                                    deleteFromCart(item);
                                                }}
                                            >
                                                <div className="spans">
                                                    <span className="h-0.5 w-9 block"></span>
                                                    <span className="h-0.5 w-9 block"></span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="text-right totalPrice px-5 font-medium pt-5">
                                Celková cena: {total ? total : null} Kč
                            </div>
                        </div>
                        <CartForm
                            total={total}
                            setDone={setDone}
                            setItems={props.setItems}
                            setCartErr={props.setCartErr}
                        />
                    </div>
                ) : !done ? (
                    <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                        <img src={img} alt="kosik" className="lg:h-32 h-24 mx-auto"/>
                        <h1 className="mt-8 md:mb-12 mb-8">Váš košík je prázdný</h1>
                        <Link to={`${ROUTES.UVOD}#domu`}>
                            <button className="text-white p-3 rounded-lg ">
                                Zpět na úvodní stranu
                            </button>
                        </Link>
                    </div>
                ) : (
                    <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                        <img src={doneImg} alt="done" className="lg:h-32 h-24 mx-auto"/>
                        <h1 className="mt-8 md:mb-12 mb-8">Objednávka proběhla úspěšně</h1>
                        <Link to={`${ROUTES.UVOD}#domu`}>
                            <button className="text-white p-3 rounded-lg ">
                                Zpět na úvodní stranu
                            </button>
                        </Link>
                    </div>
                )}
            </motion.div>
    );
};

export default Kosik;
