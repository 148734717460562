import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Services from "../components/Services";
import {useSearchParams} from "react-router-dom";

const Test = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();


	return (
		<Services
			setCartErr={props.setCartErr}
			setItems={props.setItems}
			setTotalPrice={props.setTotalPrice}
			totalPrice={props.totalPrice}
		/>
	);
};

export default Test;
