
const About = () => {
	return (
		<div
			className="about section flex justify-center items-center lg:-mt-32 h-auto"
			id="o-mne"
		>
			<div
				className="lg:py-44 py-16 text-center"
				data-aos="fade-up"
				data-aos-duration="1500"
				data-aos-offset="350"
			>
				<h1 className="uppercase font-semibold mb-5">o mně</h1>
				<div className="line md:w-24 w-16 h-1 mx-auto"></div>
				<p className="md:mt-16 mt-8 mx-auto md:w-3/5 px-3">
					Jmenuji se Jan Krasinský, spousta z&nbsp;Vás mě může znát pod pojmem
					#Anabolichorse. Sportu se věnuji celý život a&nbsp;posledních 8 let mě
					pohltil lift takovým stylem, že na&nbsp;ostatní sporty není čas. Jsem
					powerlifter, physique, strongman, youtuber, atlet Strengthwars
					a&nbsp;to nejdůležitější, budoucí taťka! Nechci nikdy zabřednout do
					jednoho odvětví toho sportu, chci zvedat rakety a&nbsp;zároveň zůstat
					komplexní atlet! Pokud to čteš, tak si blázen a&nbsp;srdcař jako já
					a&nbsp;tahle stránka je přesně pro&nbsp;tebe!
				</p>
			</div>
		</div>
	);
};

export default About;