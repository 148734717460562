import React, { useState, createRef, useEffect } from 'react';
import axios from 'axios';
import * as ROUTES from '../constants/routes';
import { ADDRESS } from '../constants/routes';
import { AiOutlineConsoleSql, AiOutlineFileImage } from 'react-icons/ai';
export default function BetterFileUploader({
	importFilesToState,
	startFiles,
	deleteFun,
	onlyOne,
	text,
	type,
}) {
	const [message, setMessage] = useState('');
	const [impFiles, setImpFiles] = useState([]);
	const fileInputRef = createRef();
	const [start, setStart] = useState([]);
	const [, updateState] = useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const dragOver = e => {
		e.preventDefault();
	};

	const dragEnter = e => {
		e.preventDefault();
	};

	const dragLeave = e => {
		e.preventDefault();
	};
	const fileUpload = e => {
		e.preventDefault();
		setMessage('');
		let toImport = null;
		const files = e.dataTransfer.files;
		if (files.length) {
			toImport = handleFiles(files);
		}
		importFilesToState(toImport);
		forceUpdate();
		console.log(toImport);
	};
	const fileInputClicked = () => {
		fileInputRef.current.click();
	};
	const deleteFoto = e => {
		let myArr = impFiles.filter(file => file.name != e.name);
		// if (myArr.length < 1 && start.length < 1) {
		// 	setMessage('Nelze odstranit všechny fotky!');
		// } else {
		// }
		setImpFiles(myArr);
		importFilesToState(myArr);
	};
	const deleteFotoExist = e => {
		let myArr = start.filter(file => file != e);
		// if (myArr.length < 1 && impFiles.length < 1) {
		// 	setMessage('Nelze odstranit všechny fotky!');
		// } else {
		// }
		setStart(myArr);
		deleteFun(e);
	};
	const filesSelected = (event) => {
		setMessage('');
		let toImport = null;
		if (fileInputRef.current.files.length) {
			toImport = handleFiles(fileInputRef.current.files);
		}
		forceUpdate();
		importFilesToState(toImport);
	};
	const validateFile = file => {
		const validTypes = [
			'image/jpeg',
			'image/jpg',
			'image/png',
			'image/gif',
			'image/x-icon',
		];
		if (validTypes.indexOf(file.type) === -1) {
			setMessage('Tento formát není podporován.');
			return false;
		}

		for (let j = 0; j < impFiles.length; j++) {
			if (file.name == impFiles[j].name) {
				setMessage(`Soubor ${file.name} je již vložen.`);
				return false;
			}
		}
		return true;
	};
	const handleFiles = files => {
		console.log(files);
		let myArr = [];
		if (onlyOne) {
			if (validateFile(files[0])) {
				setImpFiles([files[0]]);
				return [files[0]];
			}
		} else {
			for (let i = 0; i < files.length; i++) {
				if (validateFile(files[i])) {
					myArr = impFiles;
					myArr.push(files[i]);
					setImpFiles(myArr);
				}
			}
			return myArr;
		}
	};
	return (
		<div
			className="drop-container "
			onDragOver={dragOver}
			onDragEnter={dragEnter}
			onDragLeave={dragLeave}
			onDrop={fileUpload}
		>
			<div
				className={'dnd-input'}
				onClick={fileInputClicked}
				style={{ cursor: 'pointer' }}
			>
				<input
					ref={fileInputRef}
					className="file-input"
					type="file"
					multiple={onlyOne ? false : true}
					onChange={(event) => {filesSelected(event)}}
					style={{ display: 'none' }}
				/>
				<AiOutlineFileImage size={'2em'} color="#2D2D2D" />
				<p className="dropzone-text text-center">{text}</p>
			</div>
			{impFiles.length > 0 && (
				<div className="w-full my-1 flex flex-row justify-center">
					{start.map(e => (
						<div className="viewImage" key={e}>
							<img
								src={ROUTES.ADDRESS + '/storage/' + e}
								alt="Image"
								width="100"
								height="100"
								style={{ display: 'inline-block' }}
							></img>
							<span className="deleteIcon" onClick={() => deleteFotoExist(e)}>
								X
							</span>
						</div>
					))}

					{impFiles.map(e => (
						<div className="viewImage" key={e.name}>
							<img
								src={URL.createObjectURL(e)}
								alt="Image"
								width="100"
								height="100"
								style={{ display: 'inline-block' }}
							></img>
							<span className="deleteIcon" onClick={() => deleteFoto(e)}>
								X
							</span>
						</div>
					))}
				</div>
			)}

			{message != '' && (
				<p className="text-xs w-full text-center  py-1 text-red-500">
					{message}
				</p>
			)}
		</div>
	);
}
