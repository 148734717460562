import {motion} from 'framer-motion';
import {pageAnimation} from '../components/animations';
import React, {useState, useEffect} from 'react';
import img from '../assets/images/cart2.svg';
import doneImg from '../assets/images/done.png';
import errorImg from '../assets/images/error.png';
import {HashLink as Link} from 'react-router-hash-link';
import * as ROUTES from '../constants/routes';
import {useLocation} from "react-router";
import axios from "axios";
import {ADDRESS} from "../constants/routes";

const Stav = props => {
    const [done, setDone] = useState(false);
    const [products, setProducts] = useState([]);
    const [mess, setMess] = useState("");

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const urlQuery = useQuery();
    useEffect(() => {
        let typeMessage = "";
        let status = urlQuery.get("status");
        if (status !== null) {
            if (status === "success") {
                typeMessage = (
                    <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                        <img src={doneImg} alt="done" className="lg:h-32 h-24 mx-auto"/>
                        <h1 className="mt-8 md:mb-12 mb-8">Objednávka proběhla úspěšně</h1>
                        <Link to={`${ROUTES.UVOD}#domu`}>
                            <button className="text-white p-3 rounded-lg ">
                                Zpět na úvodní stranu
                            </button>
                        </Link>
                    </div>
                );
                setMess(typeMessage);

                setDone(true);
            } else if (status === "email") {

                typeMessage = (
                    <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                        <img src={doneImg} alt="done" className="lg:h-32 h-24 mx-auto"/>
                        <h1 className="mt-8 md:mb-12 mb-8">Objednávka proběhla úspěšně</h1>
                        <p className="mt-8 md:mb-12 mb-8">Byl vám již zaslán email s potvrzením objednávky.</p>
                        <Link to={`${ROUTES.UVOD}#domu`}>
                            <button className="text-white p-3 rounded-lg ">
                                Zpět na úvodní stranu
                            </button>
                        </Link>
                    </div>
                );
                setMess(typeMessage);
                setDone(true);
            } else if (status === "failed") {
                let uid = urlQuery.get("uid");

                axios.get(ADDRESS+"/state/" + uid).then((data) => {
                    console.log(data.data);
                    typeMessage = (
                        <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                            <img src={errorImg} alt="done" className="lg:h-32 h-24 mx-auto"/>
                            <h1 className="mt-8 mb-4">Platba neproběhla v pořádku</h1>
                            <h4 className="mb-6">Platbu můžete zaplatit zde</h4>
                            <a href={data.data}>
                                <button className="text-white p-3 rounded-lg ">
                                    Zaplatit platbu
                                </button>
                            </a>
                        </div>
                    );
                    setMess(typeMessage);

                })

            }
        }
    }, []);

    return (<motion.div
            variants={pageAnimation}
            initial="hidden"
            animate="enter"
            exit="exit"
            transition={{type: 'linear'}}
            className="text-white z-1 kosik md:pt-48 pt-36 md:px-12 px-4"
        >
            {
                mess
            }
        </motion.div>
    );
}
export default Stav;
