import './App.css';
import './styles.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './components/Header';
import Footer from './components/Footer';

import HlavniStranka from './pages/HlavniStranka';
import Kosik from './pages/Kosik';
import Stav from './pages/stav';
import GDPR from './pages/GDPR';
import VOP from './pages/VOP';
import NotFound from './pages/NotFound';
import NutricniAnamneza from './pages/NutricniAnamneza';
import Services from "./components/Services";
import Test from "./pages/Test";

function App() {
	const [cartErr, setCartErr] = useState('');
	const [totalPrice, setTotalPrice] = useState(0);
	const [items, setItems] = useState(0);


	function hashLinkScroll() {
		const { hash } = window.location;
		if (hash !== '') {
			setTimeout(() => {
				const id = hash.replace('#', '');
				const element = document.getElementById(id);
				if (element) element.scrollIntoView();
			}, 0);
		}
	}
	useEffect(() => {
		AOS.init({
			once: true,
		});

		if(sessionStorage.getItem('products') !== null) {
			setItems(JSON.parse(sessionStorage.getItem('products')).length);
		}
	}, []);
	return (
		<div className="flex flex-col justify-between min-h-screen">
			<BrowserRouter>
				<Header items={items} />
				<Routes>
					<Route
						path={ROUTES.UVOD}
						onUpdate={hashLinkScroll}
						element={
							<HlavniStranka
								cartErr={cartErr}
								setCartErr={setCartErr}
								setItems={setItems}
								setTotalPrice={setTotalPrice}
								totalPrice={totalPrice}
							/>
						}
						exact
					/>
					<Route
						path={ROUTES.KOSIK}
						onUpdate={hashLinkScroll}
						element={
							<Kosik
								cartErr={cartErr}
								setCartErr={setCartErr}
								setItems={setItems}
								setTotalPrice={setTotalPrice}
								totalPrice={totalPrice}
							/>
						}
						exact
					/>

					<Route
						path={ROUTES.NUTRICNI_ANAMNEZA}
						onUpdate={hashLinkScroll}
						element={
							<NutricniAnamneza />
						}
						exact
					/>
					<Route
						path={"/XD"}
						onUpdate={hashLinkScroll}
						element={
							<Test
								cartErr={cartErr}
								setCartErr={setCartErr}
								setItems={setItems}
								setTotalPrice={setTotalPrice}
								totalPrice={totalPrice}
							/>
						}
						exact
					/>
					<Route
						path={ROUTES.ORDER_FINISH}
						onUpdate={hashLinkScroll}
						element={
							<Stav
								cartErr={cartErr}
								setCartErr={setCartErr}
								setItems={setItems}
								setTotalPrice={setTotalPrice}
								totalPrice={totalPrice}
							/>
						}
						exact
					/>
					<Route
						path={"/sranda"}
						onUpdate={hashLinkScroll}
						element={
							<Stav
								cartErr={cartErr}
								setCartErr={setCartErr}
								setItems={setItems}
								setTotalPrice={setTotalPrice}
								totalPrice={totalPrice}
							/>
						}
						exact
					/>
					<Route
						path={ROUTES.GDPR}
						onUpdate={hashLinkScroll}
						element={<GDPR />}
						exact
					/>
					<Route
						path={ROUTES.VOP}
						onUpdate={hashLinkScroll}
						element={<VOP />}
						exact
					/>
					<Route path="*" element={<NotFound />}/>
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
