export const ADDRESS =
	process.env.NODE_ENV === 'production'
		? 'https://api.jankrasinsky.cz'
		: 'http://127.0.0.1:8000';

export const UVOD = '/';
export const KOSIK = '/kosik';
export const ORDER_FINISH = '/kosik/stav';
export const COOKIES = '/cookies';
export const GDPR = '/gdpr';
export const VOP = '/obchodni-podminky';
export const NOT_FOUND = '/not-found';
export const NUTRICNI_ANAMNEZA = '/nutricni-anamneza';

export const ADMIN = '/admin';
