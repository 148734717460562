import { useRef, useState, useEffect } from 'react';
import axios from "axios";
import {ADDRESS} from "../constants/routes";

const CartForm = props => {
	const input1 = useRef();
	const input2 = useRef();
	const [submited, setSubmited] = useState(0);
	const [nameErr, setNameErr] = useState('');
	const [surnameErr, setSurnameErr] = useState('');
	const [emailErr, setEmailErr] = useState('');
	const [phoneNumberErr, setPhoneNumberErr] = useState('');
	const [streetErr, setStreetErr] = useState('');
	const [houseNumberErr, setHouseNumberErr] = useState('');
	const [townErr, setTownErr] = useState('');
	const [CINErr, setCINErr] = useState('');
	const [postalCodeErr, setPostalCodeErr] = useState('');

	const transferFunction = e => {
		if (e.target.checked) {
			sessionStorage.setItem('payment', 'převodem');
		}
	};

	const cardFunction = e => {
		if (e.target.checked) {
			sessionStorage.setItem('payment', 'kartou');
		}
	};

	useEffect(() => {
		if (sessionStorage.getItem('payment')) {
			if (sessionStorage.getItem('payment') === 'kartou') {
				input1.current.defaultChecked = 'checked';
			} else {
				input2.current.defaultChecked = 'checked';
			}
		} else {
			input1.current.defaultChecked = 'checked';
			sessionStorage.setItem('payment', 'kartou');
		}
	}, []);

	const validate = e => {
		e.preventDefault();
		setNameErr('');
		setSurnameErr('');
		setEmailErr('');
		setPhoneNumberErr('');
		setStreetErr('');
		setHouseNumberErr('');
		setTownErr('');
		setCINErr('');
		setPostalCodeErr('');

		if (!sessionStorage.getItem('name')) {
			setNameErr('Povinné pole!');
		} else if (sessionStorage.getItem('name').length === 0) {
			setNameErr('Povinné pole!');
		} else if (
			!sessionStorage
				.getItem('name')
				.match(/[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,20}$/)
		) {
			setNameErr('Jméno není zadáno správně');
		}

		if (!sessionStorage.getItem('last_name')) {
			setSurnameErr('Povinné pole!');
		} else if (
			!sessionStorage
				.getItem('last_name')
				.match(/[A-Z][a-zA-Z][^#&<>"~;$^%{}?]{1,20}$/)
		) {
			setSurnameErr('Příjmení není zadáno správně!');
		} else if (sessionStorage.getItem('last_name').length === 0) {
			setSurnameErr('Povinné pole!');
		}

		if (!sessionStorage.getItem('email')) {
			setEmailErr('Povinné pole!');
		} else if (
			!sessionStorage
				.getItem('email')
				.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)
		) {
			setEmailErr('Email není zadán správně!');
		} else if (sessionStorage.getItem('email').length === 0) {
			setEmailErr('Povinné pole!');
		}

		if (!sessionStorage.getItem('phone_number')) {
			setPhoneNumberErr('Povinné pole!');
		} else if (!sessionStorage.getItem('phone_number').match(/^\d{9}$/)) {
			setPhoneNumberErr('Špatně zadané!');
		} else if (sessionStorage.getItem('phone_number').length === 0) {
			setPhoneNumberErr('Povinné pole!');
		}

		if (!sessionStorage.getItem('street')) {
			setStreetErr('Povinné pole!');
		} else if (sessionStorage.getItem('street').length === 0) {
			setStreetErr('Povinné pole!');
		}

		if (!sessionStorage.getItem('house_number')) {
			setHouseNumberErr('Povinné pole!');
		} else if (!sessionStorage.getItem('house_number').match(/^[0-9]+$/)) {
			setHouseNumberErr('Č.p. není zadáno správně!');
		} else if (sessionStorage.getItem('house_number').length === 0) {
			setHouseNumberErr('Povinné pole!');
		}

		if (!sessionStorage.getItem('town')) {
			setTownErr('Povinné pole!');
		} else if (sessionStorage.getItem('town').length === 0) {
			setTownErr('Povinné pole!');
		}

		if (
			sessionStorage.getItem('cin') &&
			sessionStorage.getItem('cin').length > 0 &&
			!sessionStorage.getItem('cin').match(/^\d{8}$/)
		) {
			setCINErr('IČO není zadané správně');
		}

		if (!sessionStorage.getItem('postal_code')) {
			setPostalCodeErr('Povinné pole!');
		} else if (
			!sessionStorage
				.getItem('postal_code')
				.match(/^(?=(\D*\d){5}\D*$)(?=[^ ]* ?[^ ]*$)[\d ]*$/)
		) {
			setPostalCodeErr('PSČ není zadáno správně!');
		} else if (sessionStorage.getItem('postal_code').length === 0) {
			setPostalCodeErr('Povinné pole!');
		}
		setSubmited(submited + 1);
	};

	// uspesna objednavka
	useEffect(() => {
		if (
			nameErr.length === 0 &&
			surnameErr.length === 0 &&
			emailErr.length === 0 &&
			phoneNumberErr.length === 0 &&
			streetErr.length === 0 &&
			houseNumberErr.length === 0 &&
			townErr.length === 0 &&
			postalCodeErr.length === 0 &&
			CINErr.length === 0 &&
			submited > 0
		) {

			const obj = {
				user_info: {
					name: sessionStorage.getItem('name'),
					last_name: sessionStorage.getItem('last_name'),
					street: sessionStorage.getItem('street'),
					postal_code: sessionStorage.getItem('postal_code'),
					phone_number: sessionStorage.getItem('phone_number'),
					house_number: sessionStorage.getItem('house_number'),
					town: sessionStorage.getItem('town'),
					payment: sessionStorage.getItem('payment'),
					email: sessionStorage.getItem('email'),
				},
				products: JSON.parse(sessionStorage.getItem('products')),
				total: props.total
			};

			// console.log(obj);

			axios.post(ADDRESS, {
					params: {
						obj,
					}
				}
			).then((data) => {
				window.location.replace(data.data);
			})

			sessionStorage.clear();
			props.setDone(true);
			props.setItems(0);
			props.setCartErr("");
			window.addEventListener('popstate', function (event) {
				props.setDone(false);
			});
		}
	}, [submited]);

	return (
		<div className="w-full form rounded-xl px-4 py-6 lg:mt-0 mt-8">
			<form action="">
				<div className="flex flex-col md:flex-row">
					<div className="w-full md:mr-2 mr-0">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="first_name"
							name="first_name"
							type="text"
							placeholder="Jméno:"
							onChange={e => {
								sessionStorage.setItem('name', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('name')
									? sessionStorage.getItem('name')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{nameErr}</p>
					</div>
					<div className="w-full md:ml-2 ml-0">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="last_name"
							name="last_name"
							type="text"
							placeholder="Příjmení:"
							onChange={e => {
								sessionStorage.setItem('last_name', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('last_name')
									? sessionStorage.getItem('last_name')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{surnameErr}</p>
					</div>
				</div>
				<div className="flex flex-col md:flex-row w-full">
					<div className="md:mr-2 mr-0 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="email"
							name="email"
							type="text"
							placeholder="Email:"
							onChange={e => {
								sessionStorage.setItem('email', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('email')
									? sessionStorage.getItem('email')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{emailErr}</p>
					</div>
					<div className="md:ml-2 ml-0 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="phone_number"
							name="phone_number"
							type="text"
							placeholder="Telefonní číslo:"
							onChange={e => {
								sessionStorage.setItem('phone_number', e.target.value);
							}}
							maxLength="9"
							defaultValue={
								sessionStorage.getItem('phone_number')
									? sessionStorage.getItem('phone_number')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{phoneNumberErr}</p>
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="md:mr-2 mr-0 md:w-2/3 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="street"
							name="street"
							type="text"
							placeholder="Ulice:"
							onChange={e => {
								sessionStorage.setItem('street', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('street')
									? sessionStorage.getItem('street')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{streetErr}</p>
					</div>
					<div className="md:ml-2 ml-0 md:w-1/3 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="house_number"
							name="house_number"
							type="text"
							placeholder="Č.p.:"
							onChange={e => {
								sessionStorage.setItem('house_number', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('house_number')
									? sessionStorage.getItem('house_number')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{houseNumberErr}</p>
					</div>
				</div>
				<div className="md:mr-2 mr-0 w-full">
					<input
						className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
						id="town"
						name="town"
						type="text"
						placeholder="Město:"
						onChange={e => {
							sessionStorage.setItem('town', e.target.value);
						}}
						defaultValue={
							sessionStorage.getItem('town')
								? sessionStorage.getItem('town')
								: ''
						}
					/>
					<p className="h-6 text-left text-red-500 mt-1">{townErr}</p>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="md:mr-2 mr-0 md:w-1/2 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="postal_code"
							name="postal_code"
							type="text"
							placeholder="PSČ:"
							onChange={e => {
								sessionStorage.setItem('postal_code', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('postal_code')
									? sessionStorage.getItem('postal_code')
									: ''
							}
						/>
						<p className="h-6 text-left text-red-500 mt-1">{postalCodeErr}</p>
					</div>
					<div className="md:ml-2 ml-0 md:w-1/2 w-full">
						<input
							className="font-medium md:h-12 h-8 appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow-outline"
							id="cin"
							name="cin"
							type="text"
							placeholder="IČO:"
							onChange={e => {
								sessionStorage.setItem('cin', e.target.value);
							}}
							defaultValue={
								sessionStorage.getItem('cin')
									? sessionStorage.getItem('cin')
									: ''
							}
							maxLength="8"
						/>
						<p className="h-6 text-left text-red-500 mt-1">{CINErr}</p>
					</div>
				</div>
				<div className="platba md:flex mt-7 mb-2 items-center md:mx-2 mx-0">
					<div className="font-medium md:mb-0 mb-3">Platba:</div>
					<div className="flex flex-row md:px-2 px-0">
						<label className="px-1 relative overflow-hidden cursor-pointer">
							<input
								type="radio"
								name="payment"
								value="card"
								className="absolute"
								onChange={cardFunction}
								ref={input1}
								defaultChecked={true}
							/>
							<span className="flex align-middle">Kartou</span>
						</label>
						<label className="px-1 relative overflow-hidden cursor-pointer">
							<input
								type="radio"
								name="payment"
								value="transfer"
								className="absolute"
								onChange={transferFunction}
								ref={input2}
							/>
							<span className="flex align-middle">Převodem</span>
						</label>
					</div>
				</div>
				<div className="text-center mt-9">
					<button
						className="objednat_btn mx-auto text-white px-6 py-2 rounded-xl uppercase font-semibold"
						onClick={e => {
							validate(e);
						}}
					>
						Objednat
					</button>
				</div>
			</form>
		</div>
	);
};

export default CartForm;
