import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import instagram from "../assets/images/instagram.png";
import youtube from "../assets/images/youtube.png";
import facebook from "../assets/images/facebook.png";

import { photoAnimation } from './animations';
import { motion } from 'framer-motion';
import * as ROUTES from '../constants/routes';
import { HashLink } from 'react-router-hash-link';
const Uvodka = () => {
	const [btnActive, setBtnActive] = useState(false);
	const btnRef = useRef();
	useEffect(() => {
		if (btnActive) {
			gsap.to(btnRef.current, {
                scale: 5,
                width: '100%',
                height: '100%',
                duration: .7
			});
		} else {
			gsap.to(btnRef.current, {
				scale: 1,
				width: 0,
				height: 0,
				duration: .7
			});
		}
	}, [btnActive]);
	return (
		<div className="overflow-hidden main">
			<motion.div
				className="uvodka"
				id="domu"
				initial="hidden"
				animate="show"
				variants={photoAnimation}
			>
				<motion.div className="container relative flex justify-center items-center w-full">
					<motion.div className="text-center px-5">
						<h1
							data-aos="fade-right"
							data-aos-easing="linear"
							data-aos-duration="700"
							data-aos-delay="500"
							className="uppercase font-semibold italic"
						>
							jan krasinský
						</h1>
						<p
							className="my-12 overflow-hidden"
							data-aos="fade-right"
							data-aos-easing="linear"
							data-aos-duration="700"
							data-aos-delay="800"
						>
							{/*Lorem ipsum dolor sit amet, consectetuer adipiscing elit.*/}
							{/*Suspendisse nisl.*/}
							{/*<br />*/}
							{/*Excepteur sint occaecat cupidatat non proident.*/}
						</p>
						<div
							data-aos="fade-right"
							data-aos-easing="linear"
							data-aos-duration="700"
							data-aos-delay="1100"
						>
							<HashLink to={`${ROUTES.UVOD}#o-mne`}>
								<button
									className="border-2 border-white px-4 py-2 relative overflow-hidden md:w-60 w-44 md:h-12 h-10"
									onMouseEnter={() => {
										setBtnActive(true);
									}}
									onMouseLeave={() => {
										setBtnActive(false);
									}}
								>
									<div className="z-20 absolute top-0 left-0 w-full h-full flex items-center justify-center">
										Zjistit více
									</div>
									<div
										ref={btnRef}
										className="w-0 h-0 absolute bottom-0 left-0 btn-bg rounded-full z-1"
									></div>
								</button>
							</HashLink>
						</div>
					</motion.div>
					<div className="socials absolute md:bottom-8 bottom-4 md:right-5 right-5">
						<ul className="flex gap-x-3">
							<li>
								<a
									href="https://www.instagram.com/krasinsky/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={instagram}
										alt="instagram"
										className="md:w-9 w-8 cursor-pointer"
									/>
								</a>
							</li>
							<li>
								<a
									href="https://www.youtube.com/c/AnabolicHorse"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={youtube}
										alt="youtube"
										className="md:w-9 w-8 cursor-pointer"
									/>
								</a>
							</li>
							<li>
								<a
									href="https://www.facebook.com/JanKrasinsky"
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={facebook}
										alt="facebook"
										className="md:w-9 w-8 cursor-pointer"
									/>
								</a>
							</li>
						</ul>
					</div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default Uvodka;
