import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animations';
import ScrollToTop from '../components/ScrollToTop';

const VOP = () => {
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className="text-white z-1 vop md:pt-48 pt-36 w-full px-4"
		>
			<ScrollToTop />
			<div className="xl:pb-24 lg:pb-12 pt-6 pb-12">
				<div className="vop-wrapper">
					<h1 className="font-semibold mb-7 uppercase">
						Všeobecné obchodní podmínky
					</h1>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">I. Základní ustanovení</h3>
						<ul className="mt-5 ml-3">
							<li className="list-decimal">
								Tyto všeobecné obchodní podmínky (dále jen{' '}
								<span className="font-semibold">„obchodní podmínky“</span>) jsou
								vydané dle §&nbsp;1751 a násl. zákona č.&nbsp;89/2012&nbsp;Sb.,
								občanský zákoník (dále jen{' '}
								<span className="font-semibold">„občanský zákoník“</span>)
							</li>
						</ul>
						<p className="block mt-3">Jan Krasinský</p>
						<p className="block mt-3">
							IČ: <span className="font-semibold">06346804</span>
						</p>
						<p className="block mt-3">
							se sídlem:{' '}
							<span className="font-semibold">Žďárská 145, Halže</span>
						</p>
						<p className="mt-5 border-b border-black">Kontaktní údaje:</p>
						<p className="mt-5">Info@jankrasinsky.cz</p>
						<p className="mt-3">721&nbsp;692&nbsp;782</p>
						<p className="mt-3">jankrasinsky.cz</p>
						<p className="mt-3">
							(dále jen <span className="font-semibold">„prodávající“</span>)
						</p>
						<ul className="mt-5">
							<li className="list-decimal">
								Tyto obchodní podmínky upravují vzájemná práva a&nbsp;povinnosti
								prodávajícího a&nbsp;fyzické osoby, která uzavírá kupní smlouvu
								mimo svoji podnikatelskou činnost jako spotřebitel, nebo
								v&nbsp;rámci své podnikatelské činnosti (dále jen:{' '}
								<span className="font-semibold">„kupující“</span>)
								prostřednictvím webového rozhraní umístěného na&nbsp;webové
								stránce dostupné na&nbsp;internetové adrese
								jankrasinsky.cz…(dále jen
								<span className="font-semibold">„internetový obchod“</span>).
							</li>
							<li className="list-decimal">
								Ustanovení obchodních podmínek jsou nedílnou součástí kupní
								smlouvy. Odchylná ujednání v&nbsp;kupní smlouvě mají přednost
								před ustanoveními těchto obchodních podmínek.
							</li>
							<li className="list-decimal">
								Tyto obchodní podmínky a&nbsp;kupní smlouva se uzavírají
								v&nbsp;českém jazyce.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							II. Informace o zboží a cenách
						</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Informace o&nbsp;zboží, včetně uvedení cen jednotlivého zboží
								a&nbsp;jeho hlavních vlastností jsou uvedeny u&nbsp;jednotlivého
								zboží v&nbsp;katalogu internetového obchodu. Ceny zboží jsou
								uvedeny včetně daně z&nbsp;přidané hodnoty, všech souvisejících
								poplatků a&nbsp;nákladů za vrácení zboží, jestliže toto zboží
								ze&nbsp;své podstaty nemůže být vráceno obvyklou poštovní
								cestou. Ceny zboží zůstávají v&nbsp;platnosti po&nbsp;dobu,
								po&nbsp;kterou jsou zobrazovány v&nbsp;internetovém obchodě.
								Toto ustanovení nevylučuje sjednání kupní smlouvy
								za&nbsp;individuálně sjednaných podmínek.
							</li>
							<li className="list-decimal">
								Veškerá prezentace zboží umístěná v&nbsp;katalogu internetového
								obchodu je informativního charakteru a&nbsp;prodávající není
								povinen uzavřít kupní smlouvu ohledně tohoto zboží.
							</li>
							<li className="list-decimal">
								V&nbsp;internetovém obchodě jsou zveřejněny informace
								o&nbsp;nákladech spojených s&nbsp;balením a&nbsp;dodáním zboží.
								Informace o&nbsp;nákladech spojených s&nbsp;balením
								a&nbsp;dodáním zboží uvedené v&nbsp;internetovém obchodě platí
								pouze v&nbsp;případech, kdy je zboží doručováno v&nbsp;rámci
								území České republiky.
							</li>
							<li className="list-decimal">
								Případné slevy s&nbsp;kupní ceny zboží nelze navzájem
								kombinovat, nedohodne-li se prodávající s&nbsp;kupujícím jinak.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							III. Objednávka a&nbsp;uzavření kupní smlouvy
						</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Náklady vzniklé kupujícímu při použití komunikačních prostředků
								na&nbsp;dálku v&nbsp;souvislosti s&nbsp;uzavřením kupní smlouvy
								(náklady na&nbsp;internetové připojení, náklady
								na&nbsp;telefonní hovory), hradí kupující sám. Tyto náklady se
								neliší od&nbsp;základní sazby.
							</li>
							<li className="list-decimal">
								Kupující provádí objednávku zboží těmito způsoby:
								<ul>
									<li className="list-disc">
										prostřednictvím svého zákaznického účtu, provedl-li
										předchozí registraci v&nbsp;internetovém obchodě,
									</li>
									<li className="list-disc">
										vyplněním objednávkového formuláře bez&nbsp;registrace.
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Při zadávání objednávky si kupující vybere zboží, počet kusů
								zboží, způsob platby a&nbsp;doručení.
							</li>
							<li className="list-decimal">
								Před odesláním objednávky je kupujícímu umožněno kontrolovat
								a&nbsp;měnit údaje, které do&nbsp;objednávky vložil. Objednávku
								odešle kupující prodávajícímu kliknutím na&nbsp;tlačítko
								„objednat“. Údaje uvedené v&nbsp;objednávce jsou prodávajícím
								považovány za&nbsp;správné. Podmínkou platnosti objednávky je
								vyplnění všech povinných údajů v&nbsp;objednávkovém formuláři
								a&nbsp;potvrzení kupujícího o&nbsp;tom, že se seznámil
								s&nbsp;těmito obchodními podmínkami.
							</li>
							<li className="list-decimal">
								Neprodleně po&nbsp;obdržení objednávky zašle prodávající
								kupujícímu potvrzení o&nbsp;obdržení objednávky na emailovou
								adresu, kterou kupující při objednání zadal. Toto potvrzení je
								automatické a&nbsp;nepovažuje se za&nbsp;uzavření smlouvy.
								Přílohou potvrzení jsou aktuální obchodní podmínky
								prodávajícího. Kupní smlouva je uzavřena až po&nbsp;přijetí
								objednávky prodávajícím. Oznámení o&nbsp;přijetí objednávky je
								doručeno na&nbsp;emailovou adresu
								kupujícího.&nbsp;/&nbsp;Neprodleně po&nbsp;obdržení objednávky
								zašle prodávající kupujícímu potvrzení o&nbsp;obdržení
								objednávky na&nbsp;emailovou adresu, kterou kupující
								při&nbsp;objednání zadal. Toto potvrzení se považuje
								za&nbsp;uzavření smlouvy. Přílohou potvrzení jsou aktuální
								obchodní podmínky prodávajícího. Kupní smlouva je uzavřena
								potvrzením objednávky prodávajícím na&nbsp;emailovou adresu
								kupujícího.
							</li>
							<li className="list-decimal">
								V&nbsp;případě, že některý z&nbsp;požadavků uvedených
								v&nbsp;objednávce nemůže prodávající splnit, zašle kupujícímu
								na&nbsp;jeho emailovou adresu pozměněnou nabídku. Pozměněná
								nabídka se považuje za&nbsp;nový návrh kupní smlouvy
								a&nbsp;kupní smlouva je v&nbsp;takovém případě uzavřena
								potvrzením kupujícího o&nbsp;přijetí této nabídky prodávajícímu
								na&nbsp;jeho emailovou adresu uvedenu v&nbsp;těchto obchodních
								podmínkách.
							</li>
							<li className="list-decimal">
								Všechny objednávky přijaté prodávajícím jsou závazné. Kupující
								může zrušit objednávku, dokud není kupujícímu doručeno oznámení
								o&nbsp;přijetí objednávky prodávajícím. Kupující může zrušit
								objednávku telefonicky na&nbsp;telefonní číslo nebo email
								prodávajícího uvedený v&nbsp;těchto obchodních podmínkách.
							</li>
							<li className="list-decimal">
								V&nbsp;případě, že došlo ke&nbsp;zjevné technické chybě na
								straně&nbsp;prodávajícího při uvedení ceny zboží
								v&nbsp;internetovém obchodě, nebo v&nbsp;průběhu objednávání,
								není prodávající povinen dodat kupujícímu zboží za&nbsp;tuto
								zcela zjevně chybnou cenu ani&nbsp;v&nbsp;případě, že kupujícímu
								bylo zasláno automatické potvrzení o&nbsp;obdržení objednávky
								podle těchto obchodních podmínek. Prodávající informuje
								kupujícího o&nbsp;chybě bez&nbsp;zbytečného odkladu a&nbsp;zašle
								kupujícímu na&nbsp;jeho emailovou adresu pozměněnou nabídku.
								Pozměněná nabídka se považuje za&nbsp;nový návrh kupní smlouvy
								a&nbsp;kupní smlouva je v&nbsp;takovém případě uzavřena
								potvrzením o&nbsp;přijetí kupujícím na&nbsp;emailovou adresu
								prodávajícího.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">IV. Zákaznický účet</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Na&nbsp;základě registrace kupujícího provedené
								v&nbsp;internetovém obchodě může kupující přistupovat
								do&nbsp;svého zákaznického účtu. Ze svého zákaznického účtu může
								kupující provádět objednávání zboží. Kupující může objednávat
								zboží také bez registrace.
							</li>
							<li className="list-decimal">
								Při&nbsp;registraci do&nbsp;zákaznického účtu a&nbsp;při
								objednávání zboží je kupující povinen uvádět správně
								a&nbsp;pravdivě všechny údaje. Údaje uvedené v&nbsp;uživatelském
								účtu je kupující při jakékoliv jejich změně povinen
								aktualizovat. Údaje uvedené kupujícím v&nbsp;zákaznickém účtu
								a&nbsp;při objednávání zboží jsou prodávajícím považovány
								za&nbsp;správné.
							</li>
							<li className="list-decimal">
								Přístup k&nbsp;zákaznickému účtu je zabezpečen uživatelským
								jménem a&nbsp;heslem. Kupující je povinen zachovávat
								mlčenlivost, ohledně informací nezbytných k&nbsp;přístupu
								do&nbsp;jeho zákaznického účtu. Prodávající nenese odpovědnost
								za případné zneužití zákaznického účtu třetími osobami.
							</li>
							<li className="list-decimal">
								Kupující není oprávněn umožnit využívání zákaznického účtu
								třetím osobám.
							</li>
							<li className="list-decimal">
								Prodávající může zrušit uživatelský účet, a&nbsp;to zejména
								v&nbsp;případě, když kupující svůj uživatelský účet déle
								nevyužívá, či v&nbsp;případě, kdy kupující poruší své povinnosti
								z&nbsp;kupní smlouvy a&nbsp;těchto obchodních podmínek.
							</li>
							<li className="list-decimal">
								Kupující bere na&nbsp;vědomí, že uživatelský účet nemusí být
								dostupný nepřetržitě, a&nbsp;to zejména s&nbsp;ohledem
								na&nbsp;nutnou údržbu hardwarového a&nbsp;softwarového vybavení
								prodávajícího, popř. nutnou údržbu hardwarového
								a&nbsp;softwarového vybavení třetích osob.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							V. Platební podmínky a&nbsp;dodání zboží
						</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Cenu zboží a&nbsp;případné náklady spojené s&nbsp;dodáním zboží
								dle kupní smlouvy může kupující uhradit následujícími způsoby:
								<ul>
									<li className="list-disc">
										bezhotovostně převodem na&nbsp;bankovní účet prodávajícího –
										číslo účtu: 2001309711, vedený u Fio&nbsp;banky,
									</li>
									<li className="list-disc">
										bezhotovostně převodem na&nbsp;účet prodávajícího
										prostřednictvím platební brány,
									</li>
									<li className="list-disc">
										dobírkou v&nbsp;hotovosti při předávání zboží,
									</li>
									<li className="list-disc">
										v&nbsp;hotovosti nebo platební kartou při osobním odběru
										výdejně zásilek.
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Společně s&nbsp;kupní cenou je kupující povinen uhradit
								prodávajícímu náklady spojené s&nbsp;balením a&nbsp;dodáním
								zboží ve&nbsp;smluvené výši. Není-li dále uvedeno výslovně
								jinak, rozumí se dále kupní cenou i&nbsp;náklady spojené
								s&nbsp;dodáním zboží.
							</li>
							<li className="list-decimal">
								V&nbsp;případě platby v&nbsp;hotovosti je kupní cena splatná při
								převzetí zboží. V&nbsp;případě bezhotovostní platby je kupní
								cena splatná do&nbsp;14 dnů od&nbsp;uzavření kupní smlouvy.
							</li>
							<li className="list-decimal">
								V&nbsp;případě platby prostřednictvím platební brány postupuje
								kupující podle&nbsp;pokynů příslušného poskytovatele
								elektronických plateb.
							</li>
							<li className="list-decimal">
								V&nbsp;případě bezhotovostní platby je závazek kupujícího
								uhradit kupní cenu splněn okamžikem připsání příslušné částky
								na&nbsp;bankovní účet prodávajícího.
							</li>
							<li className="list-decimal">
								Prodávající nepožaduje od&nbsp;kupujícího předem žádnou zálohu
								či jinou obdobnou platbu. Úhrada kupní ceny před&nbsp;odesláním
								zboží není zálohou.
							</li>
							<li className="list-decimal">
								Podle zákona o&nbsp;evidenci tržeb je prodávající povinen
								vystavit kupujícímu účtenku. Zároveň je povinen zaevidovat
								přijatou tržbu u&nbsp;správce daně online, v&nbsp;případě
								technického výpadku pak nejpozději do&nbsp;48 hodin.
							</li>
							<li className="list-decimal">
								Zboží je kupujícímu dodáno:
								<ul>
									<li className="list-disc">
										na&nbsp;adresu určenou kupujícím objednávce
									</li>
									<li className="list-disc">
										prostřednictvím výdejny zásilek na&nbsp;adresu výdejny,
										kterou kupující určil
									</li>
									<li className="list-disc">
										osobním odběrem v&nbsp;provozovně prodávajícího
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Volba způsobu dodání se provádí během objednávání zboží.
							</li>
							<li className="list-decimal">
								Náklady na&nbsp;dodání zboží v&nbsp;závislosti na&nbsp;způsobu
								odeslání a&nbsp;převzetí zboží jsou uvedeny v&nbsp;objednávce
								kupujícího a&nbsp;v&nbsp;potvrzení objednávky prodávajícím.
								V&nbsp;případě, že je způsob dopravy smluven na&nbsp;základě
								zvláštního požadavku kupujícího, nese kupující riziko
								a&nbsp;případné dodatečné náklady spojené s&nbsp;tímto způsobem
								dopravy.
							</li>
							<li className="list-decimal">
								Je-li prodávající podle kupní smlouvy povinen dodat zboží
								na&nbsp;místo určené kupujícím v&nbsp;objednávce, je kupující
								povinen převzít zboží při&nbsp;dodání. V&nbsp;případě, že je
								z&nbsp;důvodů na&nbsp;straně kupujícího nutno zboží doručovat
								opakovaně nebo jiným způsobem, než bylo uvedeno
								v&nbsp;objednávce, je kupující povinen uhradit náklady spojené
								s&nbsp;opakovaným doručováním zboží, resp. náklady spojené
								s&nbsp;jiným způsobem doručení.
							</li>
							<li className="list-decimal">
								Při&nbsp;převzetí zboží od&nbsp;přepravce je kupující povinen
								zkontrolovat neporušenost obalů zboží a&nbsp;v&nbsp;případě
								jakýchkoliv závad toto neprodleně oznámit přepravci. V případě
								shledání porušení obalu svědčícího o&nbsp;neoprávněném vniknutí
								do&nbsp;zásilky nemusí kupující zásilku od&nbsp;přepravce
								převzít.
							</li>
							<li className="list-decimal">
								Prodávající vystaví kupujícímu daňový doklad – fakturu. Daňový
								doklad je odeslán na&nbsp;emailovou adresu
								kupujícího.&nbsp;&nbsp;/Daňový doklad je přiložen
								k&nbsp;dodávanému zboží.
							</li>
							<li className="list-decimal">
								Kupující nabývá vlastnické právo ke&nbsp;zboží zaplacením celé
								kupní ceny za&nbsp;zboží, včetně nákladů na&nbsp;dodání,
								nejdříve však převzetím zboží. Odpovědnost za&nbsp;nahodilou
								zkázu, poškození či ztrátu zboží přechází na&nbsp;kupujícího
								okamžikem převzetí zboží nebo okamžikem, kdy měl kupující
								povinnost zboží převzít, ale v&nbsp;rozporu s&nbsp;kupní
								smlouvou tak neučinil.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							VI. Odstoupení od&nbsp;smlouvy
						</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Kupující, který uzavřel kupní smlouvu mimo svoji podnikatelskou
								činnost jako spotřebitel, má právo od&nbsp;kupní smlouvy
								odstoupit.
							</li>
							<li className="list-decimal">
								Lhůta pro odstoupení od&nbsp;smlouvy činí 14 dnů
								<ul>
									<li className="list-disc">ode&nbsp;dne převzetí zboží</li>
									<li className="list-disc">
										ode&nbsp;dne převzetí poslední dodávky zboží, je-li
										předmětem smlouvy několik druhů zboží nebo dodání několika
										částí
									</li>
									<li className="list-disc">
										ode&nbsp;dne převzetí první dodávky zboží, je-li předmětem
										smlouvy pravidelná opakovaná dodávka zboží.
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Kupující nemůže mimo jiné odstoupit od&nbsp;kupní smlouvy:
								<ul>
									<li className="list-disc">
										poskytování služeb, jestliže byly splněny s&nbsp;jeho
										předchozím výslovným souhlasem před uplynutím lhůty pro
										odstoupení od&nbsp;smlouvy a&nbsp;prodávající před uzavřením
										smlouvy sdělil kupujícímu, že v&nbsp;takovém případě nemá
										právo na&nbsp;odstoupení od&nbsp;smlouvy
									</li>
									<li className="list-disc">
										o&nbsp;dodávce zboží nebo služby, jejichž cena závisí
										na&nbsp;výchylkách finančního trhu nezávisle na&nbsp;vůli
										prodávajícího a&nbsp;k&nbsp;němuž může dojít během lhůty
										pro&nbsp;odstoupení od&nbsp;smlouvy
									</li>
									<li className="list-disc">
										o&nbsp;dodání alkoholických nápojů, jež mohou být dodány až
										po&nbsp;uplynutí třiceti dnů a&nbsp;jejichž cena závisí
										na&nbsp;výchylkách finančního trhu nezávislých na&nbsp;vůli
										prodávajícího
									</li>
									<li className="list-disc">
										o&nbsp;dodávce zboží, které bylo upraveno podle přání
										kupujícího nebo pro&nbsp;jeho osobu
									</li>
									<li className="list-disc">
										dodávce zboží, které podléhá rychlé zkáze, jakož
										i&nbsp;zboží, které bylo po&nbsp;dodání nenávratně smíseno
										s&nbsp;jiným zbožím
									</li>
									<li className="list-disc">
										dodávce zboží v&nbsp;uzavřeném obalu, které kupující
										z&nbsp;obalu vyňal a&nbsp;z&nbsp;hygienických důvodů jej
										není možné vrátit
									</li>
									<li className="list-disc">
										dodávce zvukové nebo obrazové nahrávky nebo počítačového
										programu, pokud porušil jejich původní obal
									</li>
									<li className="list-disc">
										dodávce novin, periodik nebo časopisů
									</li>
									<li className="list-disc">
										dodání digitálního obsahu, pokud nebyl dodán na&nbsp;hmotném
										nosiči a&nbsp;byl dodán s&nbsp;předchozím výslovným
										souhlasem kupujícího před&nbsp;uplynutím lhůty
										pro&nbsp;odstoupení od&nbsp;smlouvy a&nbsp;prodávající před
										uzavřením smlouvy sdělil kupujícímu, že v&nbsp;takovém
										případě nemá právo na&nbsp;odstoupení od&nbsp;smlouvy
									</li>
									<li className="list-disc">
										v&nbsp;dalších případech uvedených v&nbsp;§&nbsp;1837
										občanského zákoníku
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Pro&nbsp;dodržení lhůty pro&nbsp;odstoupení od&nbsp;smlouvy musí
								kupující odeslat prohlášení o&nbsp;odstoupení vevlhůtě
								pro&nbsp;odstoupení od&nbsp;smlouvy.
							</li>
							<li className="list-decimal">
								Pro&nbsp;odstoupení od&nbsp;kupní smlouvy může kupující využít
								vzorový formulář k&nbsp;odstoupení od&nbsp;smlouvy poskytovaný
								prodávajícím. Odstoupení od&nbsp;kupní smlouvy zašle kupující
								na&nbsp;emailovou nebo doručovací adresu prodávajícího uvedenou
								v&nbsp;těchto obchodních podmínkách. Prodávající potvrdí
								kupujícímu bezodkladně přijetí formuláře.
							</li>
							<li className="list-decimal">
								Kupující, který odstoupil od&nbsp;smlouvy, je&nbsp;povinen
								vrátit prodávajícímu zboží do&nbsp;14 dnů od&nbsp;odstoupení
								od&nbsp;smlouvy prodávajícímu. Kupující nese náklady spojené
								s&nbsp;navrácením zboží prodávajícímu, a&nbsp;to
								i&nbsp;v&nbsp;tom případě, kdy zboží nemůže být vráceno
								pro&nbsp;svou povahu obvyklou poštovní cestou.
							</li>
							<li className="list-decimal">
								Odstoupí-li kupující od smlouvy, vrátí mu prodávající
								bezodkladně, nejpozději však do&nbsp;14 dnů od&nbsp;odstoupení
								od&nbsp;smlouvy, všechny peněžní prostředky včetně nákladů
								na&nbsp;dodání, které od&nbsp;něho přijal, a&nbsp;to stejným
								způsobem. Prodávající vrátí kupujícímu přijaté peněžení
								prostředky jiným způsobem jen tehdy, pokud s&nbsp;tím kupující
								souhlasí a&nbsp;pokud mu tím nevzniknou další náklady.
							</li>
							<li className="list-decimal">
								Jestliže kupující zvolil jiný, než nejlevnější způsob dodání
								zboží, který prodávající nabízí, vrátí prodávající kupujícímu
								náklady na&nbsp;dodání zboží ve&nbsp;výši odpovídající
								nejlevnějšímu nabízenému způsobu dodání zboží.
							</li>
							<li className="list-decimal">
								Odstoupí-li kupující od&nbsp;kupní smlouvy, není prodávající
								povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než
								mu kupující zboží předá nebo prokáže, že zboží prodávajícímu
								odeslal.
							</li>
							<li className="list-decimal">
								Zboží musí vrátit kupující prodávajícímu nepoškozené,
								neopotřebené a&nbsp;neznečištěné a&nbsp;je-li to možné,
								v&nbsp;původním obalu. Nárok na náhradu škody vzniklé
								na&nbsp;zboží je prodávající oprávněn jednostranně započíst
								proti nároku kupujícího na&nbsp;vrácení kupní ceny.
							</li>
							<li className="list-decimal">
								Prodávající je oprávněn odstoupit od&nbsp;kupní smlouvy
								z&nbsp;důvodu vyprodání zásob, nedostupnosti zboží, anebo když
								výrobce, dovozce anebo dodavatel zboží přerušil výrobu nebo
								dovoz zboží. Prodávající bezodkladně informuje kupujícího
								prostřednictví emailové adresy uvedené v&nbsp;objednávce
								a&nbsp;vrátí ve&nbsp;lhůtě 14 dnů od&nbsp;oznámení
								o&nbsp;odstoupení od&nbsp;kupní smlouvy všechny peněžní
								prostředky včetně nákladů na&nbsp;dodání, které od&nbsp;něho
								na&nbsp;základě smlouvy přijal, a&nbsp;to stejným způsobem,
								popřípadě způsobem určeným kupujícím.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							VII. Práva z&nbsp;vadného plnění
						</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Prodávající odpovídá kupujícímu, že zboží při převzetí nemá
								vady. Zejména prodávající odpovídá kupujícímu, že v&nbsp;době,
								kdy kupující zboží převzal:
								<ul>
									<li className="list-disc">ode&nbsp;dne převzetí zboží</li>
									<li className="list-disc">
										má zboží vlastnosti, které si strany ujednaly,
										a&nbsp;chybí-li ujednání, má takové vlastnosti, které
										prodávající nebo výrobce popsal nebo které kupující očekával
										s&nbsp;ohledem na&nbsp;povahu zboží a&nbsp;na&nbsp;základě
										reklamy jimi prováděné
									</li>
									<li className="list-disc">
										se zboží hodí k&nbsp;účelu, který pro jeho použití
										prodávající uvádí nebo ke&nbsp;kterému se zboží tohoto druhu
										obvykle používá
									</li>
									<li className="list-disc">
										zboží odpovídá jakostí nebo provedením smluvenému vzorku
										nebo předloze, byla-li jakost nebo provedení určeno podle
										smluveného vzorku nebo předlohy
									</li>
									<li className="list-disc">
										je zboží v&nbsp;odpovídajícím množství, míře nebo hmotnosti
									</li>
									<li className="list-disc">
										zboží vyhovuje požadavkům právních předpisů
									</li>
								</ul>
							</li>
							<li className="list-decimal">
								Projeví-li se vada v&nbsp;průběhu šesti měsíců od&nbsp;převzetí
								zboží kupujícím, má se za to, že zboží bylo vadné již při
								převzetí. Kupující je oprávněn uplatnit právo z&nbsp;vady, která
								se vyskytne u&nbsp;spotřebního zboží v&nbsp;době dvaceti čtyř
								měsíců od&nbsp;převzetí. Toto ustanovení se nepoužije
								u&nbsp;zboží prodávaného za&nbsp;nižší cenu na&nbsp;vadu, pro
								kterou byla nižší cena ujednána, na&nbsp;opotřebení zboží
								způsobené jeho obvyklým užíváním, u&nbsp;použitého zboží
								na&nbsp;vadu odpovídající míře používání nebo opotřebení, kterou
								zboží mělo při&nbsp;převzetí kupujícím, nebo vyplývá-li to
								z&nbsp;povahy zboží.
							</li>
							<li className="list-decimal">
								V&nbsp;případě výskytu vady může kupující prodávajícímu
								předložit reklamaci a&nbsp;požadovat:
								<ul>
									<li className="list-disc">výměnu za&nbsp;nové zboží</li>
									<li className="list-disc">opravu zboží</li>
									<li className="list-disc">
										přiměřenou slevu z&nbsp;kupní ceny
									</li>
									<li className="list-disc">odstoupit od&nbsp;smlouvy</li>
								</ul>
							</li>
							<li className="list-decimal">
								Kupující má právo odstoupit od&nbsp;smlouvy
								<ul>
									<li className="list-disc">pokud má zboží podstatnou vadu</li>
									<li className="list-disc">
										pokud nemůže věc řádně užívat pro&nbsp;opakovaný výskyt vady
										nebo vad po&nbsp;opravě
									</li>
									<li className="list-disc">při větším počtu vad zboží</li>
								</ul>
							</li>
							<li className="list-decimal">
								Prodávající je povinen přijmout reklamaci v&nbsp;kterékoli
								provozovně, v&nbsp;níž je přijetí reklamace možné, případně
								i&nbsp;v&nbsp;sídle nebo místě podnikání. Prodávající je povinen
								kupujícímu vydat písemné potvrzení o&nbsp;tom, kdy kupující
								právo uplatnil, co je obsahem reklamace a&nbsp;jaký způsob
								vyřízení reklamace kupující požaduje, jakož i&nbsp;potvrzení
								o&nbsp;datu a&nbsp;způsobu vyřízení reklamace, včetně potvrzení
								o&nbsp;provedení opravy a&nbsp;době jejího trvání, případně
								písemné odůvodnění zamítnutí reklamace.
							</li>
							<li className="list-decimal">
								Prodávající nebo jím pověřený pracovník rozhodne
								o&nbsp;reklamaci ihned, ve&nbsp;složitých případech do&nbsp;tří
								pracovních dnů. Do&nbsp;této lhůty se nezapočítává doba
								přiměřená podle druhu výrobku či služby potřebná k odbornému
								posouzení vady. Reklamace včetně odstranění vady musí být
								vyřízena bezodkladně, nejpozději do&nbsp;30 dnů ode&nbsp;dne
								uplatnění reklamace, pokud se prodávající s&nbsp;kupujícím
								nedohodne na&nbsp;delší lhůtě. Marné uplynutí této lhůty se
								považuje za&nbsp;podstatné porušení smlouvy a&nbsp;kupující má
								právo od&nbsp;kupní smlouvy odstoupit. Za&nbsp;okamžik uplatnění
								reklamace se považuje okamžik, kdy dojde projev vůle kupujícího
								(uplatnění práva z vadného plnění) prodávajícímu.
							</li>
							<li className="list-decimal">
								Prodávající písemně informuje kupujícího o&nbsp;výsledku
								reklamace.
							</li>
							<li className="list-decimal">
								Právo z&nbsp;vadného plnění kupujícímu nenáleží, pokud kupující
								před převzetím věci věděl, že věc má vadu, anebo pokud kupující
								vadu sám způsobil.
							</li>
							<li className="list-decimal">
								V případě oprávněné reklamace má kupující právo na&nbsp;náhradu
								účelně vynaložených nákladů vzniklých v&nbsp;souvislosti
								s&nbsp;uplatněním reklamace. Toto právo může kupující
								u&nbsp;prodávajícího uplatnit ve&nbsp;lhůtě do&nbsp;jednoho
								měsíce po&nbsp;uplynutí záruční doby.
							</li>
							<li className="list-decimal">
								Volbu způsobu reklamace má kupující.
							</li>
							<li className="list-decimal">
								Práva a&nbsp;povinnosti smluvních stran ohledně práv
								z&nbsp;vadného plnění se řídí § 1914 až 1925, § 2099 až 2117
								a&nbsp;§&nbsp;2161 až 2174 občanského zákoníku a&nbsp;zákonem
								č.&nbsp;634/1992&nbsp;Sb., o&nbsp;ochraně spotřebitele.
							</li>
							<li className="list-decimal">
								Další práva a&nbsp;povinnosti stran související
								s&nbsp;odpovědností prodávajícího za&nbsp;vady upravuje
								reklamační řád prodávajícího.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">VIII. Doručování</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Smluvní strany si mohou veškerou písemnou korespondenci vzájemně
								doručovat prostřednictvím elektronické pošty.
							</li>
							<li className="list-decimal">
								Kupující doručuje prodávajícímu korespondenci na&nbsp;emailovou
								adresu uvedenu v&nbsp;těchto obchodních podmínkách. Prodávající
								doručuje kupujícímu korespondenci na&nbsp;emailovou adresu
								uvedenou v&nbsp;jeho zákaznickém účtu nebo v&nbsp;objednávce.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">IX. Mimosoudní řešení sporů</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								K&nbsp;mimosoudnímu řešení spotřebitelských sporů z&nbsp;kupní
								smlouvy je příslušná Česká obchodní inspekce, se sídlem
								Štěpánská 567/15, 120&nbsp;00 Praha&nbsp;2,
								IČ:&nbsp;000&nbsp;20&nbsp;869, internetová adresa:
								https://adr.coi.cz/cs. Platformu pro&nbsp;řešení sporů on-line
								nacházející se na&nbsp;internetové adrese
								http://ec.europa.eu/consumers/odr je možné využít
								při&nbsp;řešení sporů mezi prodávajícím a&nbsp;kupujícím
								z&nbsp;kupní smlouvy.
							</li>
							<li className="list-decimal">
								Evropské spotřebitelské centrum Česká republika, se&nbsp;sídlem
								Štěpánská 567/15, 120&nbsp;00 Praha&nbsp;2, internetová adresa:
								http://www.evropskyspotrebitel.cz je kontaktním místem
								podle&nbsp;Nařízení Evropského parlamentu a&nbsp;Rady (EU)
								č.&nbsp;524/2013 ze dne 21.&nbsp;května&nbsp;2013 o&nbsp;řešení
								spotřebitelských sporů on-line a&nbsp;o&nbsp;změně nařízení (ES)
								č.&nbsp;2006/2004 a&nbsp;směrnice 2009/22/ES (nařízení
								o&nbsp;řešení spotřebitelských sporů on-line).
							</li>
							<li className="list-decimal">
								Prodávající je oprávněn k&nbsp;prodeji zboží na&nbsp;základě
								živnostenského oprávnění. Živnostenskou kontrolu provádí
								v&nbsp;rámci své působnosti příslušný živnostenský úřad. Česká
								obchodní inspekce vykonává ve&nbsp;vymezeném rozsahu mimo jiné
								dozor nad&nbsp;dodržováním zákona č.&nbsp;634/1992&nbsp;Sb.,
								o&nbsp;ochraně spotřebitele.
							</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">X. Závěrečná ustanovení</h3>
						<ul className="mt-5">
							<li className="list-decimal">
								Veškerá ujednání mezi prodávajícím a&nbsp;kupujícím se právním
								řádem České republiky. Pokud vztah založený kupní smlouvou
								obsahuje mezinárodní prvek, pak strany sjednávají, že vztah se
								řídí právem České republiky. Tímto nejsou dotčena práva
								spotřebitele vyplývající z&nbsp;obecně závazných právních
								předpisů.
							</li>
							<li className="list-decimal">
								Prodávající není ve&nbsp;vztahu ke&nbsp;kupujícímu vázán žádnými
								kodexy chování ve&nbsp;smyslu ustanovení §&nbsp;1826
								odst.&nbsp;1 písm.&nbsp;e) občanského zákoníku.
							</li>
							<li className="list-decimal">
								Všechna práva k&nbsp;webovým stránkám prodávajícího, zejména
								autorská práva k&nbsp;obsahu, včetně rozvržení stránky, fotek,
								filmů, grafik, ochranných známek, loga a&nbsp;dalšího obsahu
								a&nbsp;prvků, náleží prodávajícímu. Je zakázáno kopírovat,
								upravovat nebo jinak používat webové stránky nebo jejich část
								bez&nbsp;souhlasu prodávajícího.
							</li>
							<li className="list-decimal">
								Prodávající nenese odpovědnost za&nbsp;chyby vzniklé
								v&nbsp;důsledku zásahů třetích osob dovinternetového obchodu
								nebo v&nbsp;důsledku jeho užití v&nbsp;rozporu s&nbsp;jeho
								určením. Kupující nesmí při využívání internetového obchodu
								používat postupy, které by mohly mít negativní vliv na&nbsp;jeho
								provoz a&nbsp;nesmí vykonávat žádnou činnost, která by mohla
								jemu nebo třetím osobám umožnit neoprávněně zasahovat či
								neoprávněně užít programové vybavení nebo další součásti tvořící
								internetový obchod a&nbsp;užívat internetový obchod nebo jeho
								části či softwarové vybavení takovým způsobem, který by byl
								v&nbsp;rozporu s&nbsp;jeho určením či účelem.
							</li>
							<li className="list-decimal">
								Kupující tímto přebírá na&nbsp;sebe nebezpečí změny okolností
								ve&nbsp;smyslu §&nbsp;1765 odst.&nbsp;2 občanského zákoníku.
							</li>
							<li className="list-decimal">
								Kupní smlouva včetně obchodních podmínek je archivována
								prodávajícím v&nbsp;elektronické podobě a&nbsp;není přístupná.
							</li>
							<li className="list-decimal">
								Znění obchodních podmínek může prodávající měnit či doplňovat.
								Tímto ustanovením nejsou dotčena práva a&nbsp;povinnosti vzniklá
								po&nbsp;dobu účinnosti předchozího znění obchodních podmínek.
							</li>
							<li className="list-decimal">
								Přílohou obchodních podmínek je vzorový formulář
								pro&nbsp;odstoupení od&nbsp;smlouvy.
							</li>
						</ul>
					</div>
					<p className="mt-6">
						Tyto obchodní podmínky nabývají účinnosti dnem 26.8.&nbsp;2019
					</p>
				</div>
			</div>
		</motion.div>
	);
};

export default VOP;
