export const pageAnimation = {
	hidden: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: 0.5,
			when: 'beforeChildren',
			staggerChildren: 0.25,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
};

export const navLinksAnimation = {
	hidden: {
		width: 0,
		transition: {
			duration: 0.1,
		},
	},
	enter: {
		width: '100%',
		transition: {
			duration: 0.1,
		},
	},
};

export const photoAnimation = {
	hidden: {
		scale: 1.5,
		opacity: 0,
	},
	show: {
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.75,
			ease: 'easeOut',
		},
	},
};