// animace
import { motion } from 'framer-motion';

import { useRef } from 'react';
const PersonalData = props => {
	const personalDataAnimation = {
		hidden: {
			transform: 'translateY(-300%)',
			transition: {
				duration: 1,
				ease: 'easeInOut',
			},
		},
		show: {
			transform: 'translateY(-50%)',
			transition: {
				duration: 1,
				delay: 0.3,
				ease: 'easeInOut',
			},
		},
	};

	// contact form - animation of container
	const personalDataContainerAnimation = {
		hidden: {
			display: 'none',
			opacity: 0,
			transition: {
				duration: 0.5,
				delay: 1,
				ease: 'easeInOut',
			},
		},
		show: {
			display: 'block',
			opacity: 1,
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
	};
	return (
		<>
			<motion.div
				variants={personalDataContainerAnimation}
				animate={props.showPersonalData ? 'show' : 'hidden'}
				initial="hidden"
				className="personalDataContainer fixed h-full w-full top-0 left-0"
				onClick={e => {
					if (e.target.className.includes('personalDataContainer')) {
						props.setShowPersonalData(false);
					}
				}}
			></motion.div>
			<motion.form
				className="personalData fixed bg-white px-5 py-8 rounded-lg"
				variants={personalDataAnimation}
				animate={props.showPersonalData ? 'show' : 'hidden'}
				initial="hidden"
			>
				<div className="buttonWrapper w-full relative h-4 mb-8">
					<div
						className="spansForm absolute top-0 right-0 h-6 cursor-pointer"
						onClick={() => {
							props.setShowPersonalData(false);
						}}
					>
						<span className="block w-8 h-1 mb-1"></span>
						<span className="block w-8 h-1 mt-1"></span>
					</div>
				</div>
				<h1>Souhlas se zpracováním osobních údajů</h1>
				<p className="mt-5">
					Vyplněním nutriční anamnézy beru na&nbsp;vědomí a&nbsp;akceptuji, že
					poskytovatel bude zpracovávat mé osobní údaje v&nbsp;souvislosti
					s&nbsp;poskytováním služeb, konkrétně výživového poradenství.
					Zpracovávání osobních údajů je nezbytné za&nbsp;účelem splnění
					objednaných služeb. Beru na&nbsp;vědomí, že poskytování osobních údajů
					je dobrovolné, je však podmínkou řádného poskytování služeb.
				</p>
				<p className="my-5">
					Za&nbsp;účelem vedení evidence klientů je poskytovatel oprávněn
					zpracovávat moje jméno a&nbsp;příjmení, datum narození, adresu
					bydliště, telefonní číslo a&nbsp;další kontaktní údaje. Poskytovatel
					je dále oprávněn zpracovávat údaje o&nbsp;mém zdravotním stavu
					a&nbsp;fyziologii.{' '}
				</p>
				<p>
					Jsem si vědom toho, že služby v&nbsp;oblasti výživového poradenství
					nejsou zdravotními službami a&nbsp;nenahrazují je. Trpím-li nemocí
					nebo chorobným stavem, poradím se se svým lékařem. Jsem si vědom toho,
					že výsledky služeb výživového poradenství jsou individuální,
					a&nbsp;závisí na&nbsp;mnoha faktorech, jako je například zdravotní
					stav, dodržování předepsaného režimu, pohybová aktivita apod.
				</p>
			</motion.form>
		</>
	);
};

export default PersonalData;
