import logo from '../assets/images/logo.png';
import cart from '../assets/images/cart.png';

import { motion } from 'framer-motion';
import { navLinksAnimation } from './animations';
import { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { useLocation } from 'react-router';

import * as ROUTES from '../constants/routes';
import { HashLink } from 'react-router-hash-link';

const Header = props => {
	const [firstLink, setFirstLink] = useState(false);
	const [secondLink, setSecondLink] = useState(false);
	const [thirdLink, setThridLink] = useState(false);
	const [fourthLink, setFourthLink] = useState(false);
	const [spanActive, setSpanActive] = useState(false);
	const mobileMenu = useRef();
	const mobileMenuLinks = useRef();
	const [scrollHeight, setScrollHeight] = useState(window.scrollY - 96);
	const location = useLocation();

	// 96 = vyska navbaru
	window.addEventListener('scroll', () => {
		setScrollHeight(window.scrollY - 96);
	});

	const mobileMenuData = [
		{
			id: 1,
			name: 'Domů',
			to: `${ROUTES.UVOD}#domu`,
		},
		{
			id: 2,
			name: 'O mně',
			to: `${ROUTES.UVOD}#o-mne`,
		},
		{
			id: 3,
			name: 'Služby',
			to: `${ROUTES.UVOD}#sluzby`,
		},
		{
			id: 4,
			name: 'Košík',
			to: `${ROUTES.KOSIK}`,
		},
		{
			id: 5,
			name: 'Kontakt',
			to: `${ROUTES.UVOD}#kontakt`,
		},
	];

	useEffect(() => {
		let tl = gsap.timeline();
		if (spanActive) {
			tl.to(mobileMenu.current, {
				x: 0,
				duration: 0.4,
				ease: 'linear',
			});
			if (mobileMenuLinks.current) {
				for (let i = 0; i < mobileMenuLinks.current.children.length; i++) {
					tl.fromTo(
						mobileMenuLinks.current.children[i],
						{
							x: '100vw',
							duration: 0.8,
							ease: 'linear',
							stagger: 0.1,
						},
						{
							x: 0,
							duration: 0.8,
							ease: 'linear',
						},
						'-=0.6',
					);
				}
			}
		} else {
			if (mobileMenuLinks.current) {
				for (let i = 0; i < mobileMenuLinks.current.children.length; i++) {
					tl.fromTo(
						mobileMenuLinks.current.children[i],
						{
							x: '0',
							duration: 0.8,
							ease: 'linear',
							stagger: 0.1,
						},
						{
							x: '100vw',
							duration: 0.8,
							ease: 'linear',
						},
						'-=0.6',
					);
				}
			}
			tl.to(mobileMenu.current, {
				x: '150%',
				duration: 0.4,
				ease: 'linear',
			});
		}
	}, [spanActive]);
	return (
		<motion.div
			className={
				location && location.pathname !== '/'
					? 'header header-fixed text-white w-screen fixed top-0 left-0 h-24 z-50'
					: scrollHeight > 0
					? 'header header-fixed text-white w-screen fixed top-0 left-0 h-24 z-50'
					: 'header text-white w-screen fixed top-0 left-0 h-24 z-50'
			}
		>
			<div className="header__container flex justify-between items-center w-full md:px-16 px-5 h-full">
				<HashLink
					to={`${ROUTES.UVOD}#domu`}
				>
					<div className="flex gap-x-5 z-50">
						<img src={logo} alt="logo" className="md:w-16 w-12" />
						<div className="uppercase">
							<div className="font-medium">jan krasinský</div>
							<div className="red font-medium">anabolic horse</div>
						</div>
					</div>
				</HashLink>
				<div
					className={
						spanActive
							? 'block lg:hidden activeSpans z-50'
							: 'block lg:hidden spans z-50'
					}
					onClick={() => {
						setSpanActive(!spanActive);
					}}
				>
					<span className="block h-0.5 w-6 bg-white"></span>
					<span className="block h-0.5 w-5 bg-white my-1"></span>
					<span className="block h-0.5 w-6 bg-white"></span>
				</div>
				<div className="lg:block hidden z-50">
					<motion.ul className="uppercase flex items-center gap-x-7">
						<motion.li
							onMouseEnter={() => {
								setFirstLink(true);
							}}
							onMouseLeave={() => {
								setFirstLink(false);
							}}
						>
							<HashLink to={`${ROUTES.UVOD}#domu`}>domů</HashLink>
							<motion.div
								variants={firstLink ? navLinksAnimation : null}
								initial="hidden"
								animate="enter"
								transition={{ type: 'linear' }}
								className="line w-0 mt-1"
							></motion.div>
						</motion.li>
						<motion.li
							onMouseEnter={() => {
								setSecondLink(true);
							}}
							onMouseLeave={() => {
								setSecondLink(false);
							}}
						>
							<HashLink to={`${ROUTES.UVOD}#o-mne`}>o mně</HashLink>
							<motion.div
								variants={secondLink ? navLinksAnimation : null}
								initial="hidden"
								animate="enter"
								transition={{ type: 'linear' }}
								className="line w-0 mt-1"
							></motion.div>
						</motion.li>
						<motion.li
							onMouseEnter={() => {
								setThridLink(true);
							}}
							onMouseLeave={() => {
								setThridLink(false);
							}}
						>
							<HashLink to={`${ROUTES.UVOD}#sluzby`}>služby</HashLink>
							<motion.div
								variants={thirdLink ? navLinksAnimation : null}
								initial="hidden"
								animate="enter"
								transition={{ type: 'linear' }}
								className="line w-0 mt-1"
							></motion.div>
						</motion.li>
						<motion.li
							onMouseEnter={() => {
								setFourthLink(true);
							}}
							onMouseLeave={() => {
								setFourthLink(false);
							}}
						>
							<HashLink to={`${ROUTES.UVOD}#kontakt`}>kontakt</HashLink>
							<motion.div
								variants={fourthLink ? navLinksAnimation : null}
								initial="hidden"
								animate="enter"
								transition={{ type: 'linear' }}
								className="line w-0 mt-1"
							></motion.div>
						</motion.li>
						<HashLink to={`${ROUTES.KOSIK}`}>
							<li data-content={props.items}>
								<img src={cart} alt="kosik" className="w-8" />
							</li>
						</HashLink>
					</motion.ul>
				</div>
				<div
					className="mobile__menu w-screen h-screen fixed top-0 left-0 z-40 lg:hidden block"
					ref={mobileMenu}
				>
					<ul
						ref={mobileMenuLinks}
						className="uppercase gap-y-7 flex flex-col absolute top-1/2 w-full left-0 transform -translate-y-1/2"
					>
						{mobileMenuData.map(item => {
							const { id, name, to } = item;
							return (
								<li key={id}>
									<HashLink
										to={to}
										onClick={() => {
											setSpanActive(!spanActive);
										}}
									>
										{name}
									</HashLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</motion.div>
	);
};

export default Header;
