import box1 from "../assets/images/box1.png";
import box2 from "../assets/images/box2.png";
import box3 from "../assets/images/box3.png";

const Boxes = () => {
    const boxesData = [
			{
				id: 1,
				title: 'KOMPLEXNÍ ATLET',
				text: 'Czech strongman 2021<br>' +
					'Powerlifter (340,230,370)<br>' +
					'STRENGTHWARS athlete<br>' +
					'Mens physique ( každej někdy ustřelil 😂 )<br>' +
					'Biker<br>' +
					'Crossfiťák<br>',
				icon: box1,
			},
			{
				id: 2,
				title: 'KRÁLIČÍ KREV',
				text: 'Splněný sen a k tomu nejoriginálnější preworkout v ČR',
				icon: box2,
			},
			{
				id: 3,
				title: 'YOUTUBER',
				text: 'Trendy man feat. Pavlis',
				icon: box3,
			},
		];

    return (
        <div className="boxes grid lg:grid-cols-3 grid-cols-1">
            {boxesData.map(item => {
                const {id, title, text, icon} = item;
                return (
									<div
										key={id}
										className="box lg:px-16 px-8 lg:pt-36 lg:pb-32 py-12 overflow-hidden"
									>
										<div
											data-aos="fade-right"
											data-aos-easing="linear"
											data-aos-duration="500"
											data-aos-delay={id * 200}
										>
											<img src={icon} alt="box-icon" className="lg:h-16 h-12" />
											<h3 className="lg:my-8 my-4 italic font-semibold">
												{title}
											</h3>
											<p dangerouslySetInnerHTML={{ __html: text }} />
										</div>
									</div>
								);
            })}
        </div>
    )
}

export default Boxes;
