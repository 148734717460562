import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animations';
import ScrollToTop from '../components/ScrollToTop';

const GDPR = () => {
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className="text-white z-1 gdpr md:pt-48 pt-36 px-4"
		>
			<ScrollToTop />
			<div className="xl:pb-24 lg:pb-12 pt-6 pb-12 xl:px-24 lg:px-16 ">
				<div className="gdpr-wrapper">
					<h1 className="font-semibold mb-7 uppercase">
						Zásady ochrany osobních údajů
					</h1>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">1. Sběr informací</h3>
						<p className="block mt-3">
							Shromažďujeme od&nbsp;vás kontaktní informace během objednávky
							na&nbsp;našich stránkách. Shromážděné informace zahrnují vaše
							jméno, e-mailovou adresu, korespondenční adresu, telefonní číslo
							a&nbsp;/&nbsp;nebo číslo kreditní karty.
						</p>
						<p className="block mt-3">
							Kromě toho automaticky dostáváme a&nbsp;zaznamenáváme informace
							z&nbsp;vašeho počítače a&nbsp;prohlížeče, včetně vaší IP adresy,
							atributy softwaru a&nbsp;hardwaru a&nbsp;navštívené stránky.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">2. Použití informací</h3>
						<p className="block mt-3">
							Jakékoliv informace, které od&nbsp;vás sbíráme, mohou být použity
							pro:
						</p>
						<ul>
							<li>
								Personalizaci vaší zkušenosti a&nbsp;reakci na&nbsp;vaše
								individuální potřeby
							</li>
							<li>Poskytování personalizovaného obsahu reklamní</li>
							<li>Zlepšení našich stránek</li>
							<li>Zlepšení služeb zákazníkům a&nbsp;vaše potřeby podpor</li>
							<li>Kontakt prostřednictvím e-mailu</li>
							<li>Správu soutěže, propagace nebo&nbsp;průzkum</li>
						</ul>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">3. E-Komerce Soukromí</h3>
						<p className="block mt-3">
							Jsme jedinými vlastníky informací shromážděných na&nbsp;těchto
							stránkách. Vaše osobní údaje nebudou bez&nbsp;vašeho souhlasu
							z&nbsp;žádného důvodu prodávány, vyměňovány, převáděny nebo
							poskytovány jakékoliv jiné společnost, s&nbsp;výjimkou případů,
							kdy je to nezbytné ke&nbsp;splnění požadavku a&nbsp;/&nbsp;nebo
							transakce, např. zaslání objednávky.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">
							4. Zveřejnění informací třetím stranám
						</h3>
						<p className="block mt-3">
							Vaše osobní identifikační údaje neprodáváme, nevyměňujeme nebo
							jinak převádíme třetím stranám. To nezahrnuje důvěryhodné třetí
							strany, které nám pomáhají s&nbsp;provozem našich webových stránek
							nebo vedením našeho podnikání, pokud se strany dohodnou, že tyto
							informace jsou důvěrné.
						</p>
						<p className="block mt-3">
							Věříme, že je nutné sdílet informace s&nbsp;cílem vyšetřit,
							zabránit nebo podniknout kroky v&nbsp;případech nezákonné
							činnosti, podezření z&nbsp;podvodu, situace zahrnující potenciální
							hrozby pro&nbsp;fyzickou bezpečnost jakékoli osoby, porušování
							našich podmínek používání nebo v&nbsp;jiných případech
							vyžadovaných zákonem.
						</p>
						<p className="block mt-3">
							Neosobní data však mohou být poskytnuty jiným stranám pro
							marketingové účely, reklamní nebo jiné použití.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">5. Ochrana informací</h3>
						<p className="block mt-3">
							Realizujeme celou řadu bezpečnostních opatření s&nbsp;cílem
							zajistit bezpečnost vašich osobních údajů. K&nbsp;ochraně
							citlivých informací přenášených online používáme nejmodernější
							šifrování. VpnMentor také chrání vaše informace offline. Přístup
							k&nbsp;osobním údajům je udělen pouze zaměstnancům, kteří jej
							potřebují k&nbsp;výkonu práce (například účtování nebo zákaznický
							servis). Počítače&nbsp;/&nbsp;servery sloužící kvukládání osobní
							údajů jsou uchovávány v&nbsp;bezpečném prostředí.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">Používáme cookies?</h3>
						<p className="block mt-3">
							Ano. Naše cookies zlepšují přístup na&nbsp;naše stránky
							a&nbsp;identifikují opakované návštěvy. Navíc, naše cookies
							zvyšují uživatelský zážitek pomocí sledování a&nbsp;cílení
							na&nbsp;zájmy uživatele. Použití cookies však není v&nbsp;žádném
							případě spojeno s&nbsp;žádnými osobními identifikačními údaji
							na&nbsp;našich stránkách.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">6. Odhlášení</h3>
						<p className="block mt-3">
							Poskytnutou e-mailovou adresu používáme k&nbsp;zaslání informací
							a&nbsp;aktualizací týkajících se vaší objednávky, občasným
							firemním sdělením, zaslání informací o&nbsp;výrobcích, atd. Pokud
							se kdykoliv budete chtít odhlásit od&nbsp;přijímání budoucích
							e-mailů, v&nbsp;dolní části každého e-mailu naleznete podrobný
							návod pro&nbsp;odhlášení.
						</p>
					</div>
					<div className="text mt-8">
						<h3 className="font-semibold mb-3">7. Souhlas</h3>
						<p className="block mt-3">
							Používáním našich stránek souhlasíte s&nbsp;našimi zásadami
							ochrany soukromí.
						</p>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default GDPR;
