import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animations';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import * as ROUTES from '../constants/routes';

const NotFound = () => {
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className="text-white z-1 notFoundContainer md:pt-48 pt-36 md:px-12 px-4"
		>
			<ScrollToTop />
			<div className="notFound xl:pb-24 lg:pb-12 pt-6 pb-12 xl:px-24 lg:px-16 md:px-12 px-4 mx-auto">
				<div className="notFound-wrapper text-center sm:p-8 px-4 py-6 mx-auto">
					<div className="notFound-wrapper-error">404</div>
					<div className="notFound-wrapper-text mt-4 mb-6">
						Litujeme, ale tato stránka <span className="blue">neexistuje</span>
					</div>
					<Link to={ROUTES.UVOD}>
						<button className="rounded-xl text-white px-6 py-3">
							Zpět na úvodní stránku
						</button>
					</Link>
				</div>
			</div>
		</motion.div>
	);
};

export default NotFound;
