// animations
import { motion } from 'framer-motion';
import { pageAnimation } from '../components/animations';

import Uvodka from '../components/Uvodka';
import Boxes from '../components/Boxes';
import About from '../components/About';
import Services from '../components/Services';

const HlavniStranka = props => {
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className="text-white px-0 z-1 hlavniStranka"
		>
			<Uvodka />
			<Boxes />
			<About />
			<Services
				setCartErr={props.setCartErr}
				setItems={props.setItems}
				setTotalPrice={props.setTotalPrice}
				totalPrice={props.totalPrice}
			/>
		</motion.div>
	);
};

export default HlavniStranka;
